<template>
  <!-- 计费方案 - 新增时段计费方案 -->
  <div class="box-card" style="text-align: left;">
    <!-- 主要内容 -->
    <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px" class="demo-ruleForm">
      <el-form-item label="方案名称：" prop="scheme_name">
        <el-input v-model="addForm.scheme_name" maxlength="20" @input="(e) => (addForm.scheme_name = validSpace(e))"
          placeholder="请输入方案名称"></el-input>
      </el-form-item>
      <el-form-item label="方案详情：" prop="scheme_details">
        <el-input type="textarea" maxlength="300" v-model.trim="addForm.scheme_details" :rows="5" placeholder="请输入方案详情"
          @blur="addForm.scheme_details = ((addForm.scheme_details.replace(/\r\n|\n|\r/g, '<br/>')).replace(/\s/g, '')).replace(/<br\/>/g, '\n')"></el-input>
      </el-form-item>
      <el-form-item label="免费时长：" prop="free_time">
        <div style="display: inline-block;width: 100%;">
          <el-input v-model="addForm.free_time" maxlength="9" @input="(e) => (addForm.free_time = validSpace(e))"
            placeholder="请输入免费时长"></el-input> 分钟
          <!-- <el-checkbox style="margin-left: 15px;" v-model="addForm.free_time_iscalculate">免费时长包含计算</el-checkbox> -->
        </div>
      </el-form-item>
      <el-form-item label="计费周期：" prop="billing_cycle">
        <div style="display: inline-block;width: 100%;">
          <el-input v-model="addForm.billing_cycle" maxlength="9" @input="(e) => (addForm.billing_cycle = validSpace(e))"
            placeholder="请输入计费周期"></el-input>分钟
        </div>
      </el-form-item>
      <el-form-item label="限额：" prop="is_quota">
        <div class="quota_box">
          <el-select popper-class="my-select" v-model="addForm.is_quota" placeholder="请选择方案类型" class="selectInput">
            <el-option label="未启用" :value="false"></el-option>
            <el-option label="启用" :value="true"></el-option>
          </el-select>
          <div v-if="addForm.is_quota" style="margin-left:15px;display: flex;">
            <el-form-item prop="quota_duration" class="quota_box_input">
              <el-input v-model="addForm.quota_duration" maxlength="9" style=" margin: 0 5px;"
                @input="(e) => (addForm.quota_duration = validSpace(e))"></el-input>
            </el-form-item>
            <div class="labelWidth2">
              分钟，收费
            </div>
            <el-form-item prop="quota_amount" class="quota_box_input">
              <el-input v-model="addForm.quota_amount" maxlength="9" style=" margin: 0 5px;"
                @input="(e) => (addForm.quota_amount = validSpacing(e))"></el-input>
            </el-form-item>元
          </div>
        </div>
      </el-form-item>
      <el-form-item label="计费时段：" class="time-frame" prop="cost_time_period_time">
        <div v-for="(item, index) in addForm.cost_time_period_time" :key="index">
          <div style="margin-bottom:20px;display: flex;align-items: center;">
            <el-form-item :prop="'cost_time_period_time[' + index + '].begin_time'"
              :rules="{ required: true, message: '请选择开始时间', trigger: 'blur' }" class="cost_time_period_time_input">
              <el-time-picker v-model="item.begin_time" :default-value="beginTimeShow" format="HH:mm:ss" :editable="false"
                :picker-options="{
                  selectableRange: `00:00:00-23:59:59`, format: 'HH:mm:ss'
                }" placeholder="开始时间" :disabled="(item.begin_time && index !== 0) ? true : false"
                @blur="benginTimeChange(index, item.begin_time)"></el-time-picker>
              <!-- <el-time-picker v-model="item.begin_time" :default-value="beginTimeShow" format="HH:mm:ss" :editable="false" :picker-options="{
                     selectableRange: `${addStartTime(item.begin_time,index)}`,format: 'HH:mm:ss'
                     }" placeholder="请选择开始时间" :readonly="item.begin_time ? true: false"></el-time-picker> -->
            </el-form-item>
            <span style="margin: 0 5px;">至</span>
            <el-form-item :prop="'cost_time_period_time[' + index + '].end_time'"
              :rules="{ required: true, message: '请选择结束时间', trigger: 'blur' }" class="cost_time_period_time_input">
              <el-time-picker v-model="item.end_time" :default-value="endTimeShow" format="HH:mm:ss" :editable="false"
                :picker-options="{
                  selectableRange: addEndTime(index, item), format: 'HH:mm:ss'
                }" placeholder="结束时间" @blur="endTimeChange(index, item.end_time)"></el-time-picker>
              <!-- <el-time-picker v-model="item.end_time" :default-value="endTimeShow" format="HH:mm:ss" :editable="false" :picker-options="{
                      selectableRange: `${addEndTime(item.begin_time)}`,format: 'HH:mm:ss' }" placeholder="请选择结束时间" @blur="endTimeBlur(item.end_time, index)"></el-time-picker> -->
            </el-form-item>
            <span style="margin: 0 0 0 5px;min-width: 70px;">计费方案：</span>
            <el-form-item :prop="'cost_time_period_time[' + index + '].cost_scheme_id'"
              :rules="{ required: true, message: '请选择计费方案', trigger: 'blur' }" class="cost_time_period_time_input">
              <el-select popper-class="my-select" v-model="item.cost_scheme_id" placeholder="请选择计费方案">
                <el-option v-for="(item, index) in timeCountSelect" :key="index" :label="item.scheme_name"
                  :value="item._id"></el-option>
              </el-select>
            </el-form-item>
            <div class="iconButtom">
              <el-button class="buttonCircle"  icon="el-icon-plus" size="mini" circle
                @click="addBillingTime(index)"></el-button>
              <el-button class="buttonCircleDecline"  icon="el-icon-minus" size="mini" circle v-if="index > 0"
                @click="delBillingTime(index)"></el-button>
            </div>
          </div>
          <!-- <div style="display: inline-block;margin-bottom:10px;" v-else>
                                  之后每 <el-input style="width:120px;margin: 0 15px;"></el-input> 分钟，收费
                                      <el-input style="width:120px;margin: 0 15px;"></el-input> 元
                                  </div> -->
        </div>
      </el-form-item>
    </el-form>
    <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm()" v-preventReClick>返 回</lbButton> -->
    <lbButton type="default" fill icon="confirm" class="checkButton" @click="submitAddForm()" v-preventReClick>保 存
    </lbButton>
  </div>
</template>

<script>
import { getDetailsList, getIntervalCardList, delTimeOrCount, addInterval, editInterval, getTimeCountSelectList } from '@/api/billingProject'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
export default {
  props: { time_id: { type: String } },
  data () {
    return {
      beginTimeShow: new Date('2021-5-26 00:00:00'),
      endTimeShow: new Date('2021-5-26 23:59:59'),
      title: '',
      // 新增/编辑停车场表单
      addForm: {
        _id: '',
        scheme_name: '',
        scheme_details: '',
        free_time: 60,
        free_time_iscalculate: true,
        billing_cycle: 1440,
        cost_time_period_time: [{
          // time: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
          begin_time: '',
          end_time: '',
          cost_scheme_id: ''
        }],
        is_quota: false,
        quota_duration: '',
        quota_amount: ''
      },
      // 新增/编辑停车场表单验证
      addFormRules: {
        scheme_name: [
          { required: true, message: '请输入方案名称', trigger: 'blur' }
        ],
        // scheme_type: [
        //   { required: true, message: '请选择方案类型', trigger: 'blur' },
        // ],
        scheme_details: [
          { required: true, message: '请输入方案详情', trigger: 'blur' }
        ],
        free_time: [
          { required: true, message: '请输入免费时长', trigger: 'blur' },
          { pattern: /^[0-9]*$/, message: '格式错误，请输入数字型数据', trigger: 'blur' }
        ],
        billing_cycle: [
          { required: true, message: '请输入计费周期', trigger: 'blur' },
          { pattern: /^[0-9]*$/, message: '格式错误，请输入数字型数据', trigger: 'blur' }
        ],
        is_quota: [
          { required: true, message: '请选择是否启用限额', trigger: 'blur' }
        ],
        cost_time_period_time: [
          { type: 'array', required: true, message: '请输入计费时段', trigger: 'blur' }
        ],
        quota_duration: [
          { required: true, message: '请输入限额时间', trigger: 'blur' },
          { pattern: /^[0-9]*$/, message: '格式错误，请输入数字型数据', trigger: 'blur' }
        ],
        quota_amount: [
          { required: true, message: '请输入限额金额', trigger: 'blur' },
          { pattern: /^([1-9]\d*|0)(\.\d{1,2})?$/, message: '请输入带两位小数的数字', trigger: 'blur' }
          // { pattern: /^(0\.([1-9]|\d[1-9])$|^0\.([1-9]|\d[0-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8})$/, message: '请输入带两位小数的数字', trigger: 'blur' }
        ]
      },
      // 计时计次方案下拉列
      timeCountSelect: []
    }
  },
  created () {
    this.fngetTimeCountSelectList()
    if (this.time_id) {
      this.title = '编辑时段计费方案'
      this.fngetDetailsList()
    } else {
      this.title = '新增时段计费方案'
    }
  },
  mounted () {
  },
  computed: {
    ...mapState(['parkingList']),
    ...mapState('menuList', ['isRoutW']),
    ...mapGetters(['getOptions', 'getWayName'])
  },
  methods: {
    ...mapActions(['requestPorkingList', 'requestFindList']),
    ...mapMutations(['setQueryStatus']),
    goBack () {
      this.setQueryStatus(2)
      this.$router.go(-1)
    },
    //  计时计次方案下拉列
    async fngetTimeCountSelectList () {
      const res = await getTimeCountSelectList()
      // console.log(res);
      this.timeCountSelect = res && res.Code === 200 ? res.Data : []
    },
    // 添加计费时段
    addBillingTime (index) {
      var time = this.addForm.cost_time_period_time[index].end_time
      var beginTime = ''
      if (time) {
        var d = this.$moment(time).format('x')
        beginTime = this.$moment(Number(d) + 1000)._d
      }
      var timer = {
        begin_time: beginTime,
        end_time: '',
        cost_scheme_id: ''
      }
      this.addForm.cost_time_period_time.splice(index + 1, 0, timer)
      // var timer = {
      //   begin_time: '',
      //   end_time: '',
      //   cost_scheme_id: '',
      // }
      // this.addForm.cost_time_period_time.splice(index + 1, 0, timer)
    },
    // 结束时间加一秒为下一开始时间
    // addEndTime(date) {
    // console.log(date);
    //   var d = this.$moment(date).format('x');
    //   return this.$moment(Number(d) + 1000).format('HH:mm:ss') + '-23:59:59'
    // },
    // 开始
    // addStartTime(date, index) {
    //   if (index === 0) {
    //     return '00:00:00-23:59:59'
    //   }
    // },
    // 结束时间失去焦点时
    // endTimeBlur(time, index) {
    //   if (this.addForm.cost_time_period_time[index + 1]) {
    //     if (this.$moment(time).format('HH:mm:ss') !== '23:59:59') {
    //       var arr = this.addForm.cost_time_period_time.concat()
    //       var indexArr = []
    //       if (this.addForm.cost_time_period_time[index + 1].end_time) {
    //         arr.forEach((item, j) => {
    //           var x = this.$moment(time)
    //           var y = this.$moment(item.end_time)
    //           var duration = x.diff(y)
    //           if (j > index && duration >= 0) {
    //             indexArr.push(j)
    //           }
    //         })
    //       }
    //       var d = this.$moment(time).format('x');
    //       this.addForm.cost_time_period_time[index + 1].begin_time = this.$moment(Number(d) + 1000)._d
    //       indexArr.forEach(item => {
    //         this.delBillingTime(item)
    //       })
    //     } else {
    //       this.addForm.cost_time_period_time.splice(index + 1)
    //     }
    //   }
    // },
    // 开始时间发生改变时触发
    benginTimeChange (index, begin_time) {
      // console.log('--开始时间---index--', index);
      // console.log('--开始时间---begin_time-', begin_time);
      if (this.addForm.cost_time_period_time[index + 1]) {
        // 遍历所有时间，若第一个时段的开始时间 >= 后一个时段的开始时间，则删除后一个时段，否则结束循环，不做处理
        var arr = this.addForm.cost_time_period_time.concat()
        var indexArr = []
        var x = this.$moment(begin_time)
        for (var i = 1; i < arr.length - 1; i++) {
          var y = this.$moment(arr[i].begin_time)
          x.diff(y)
          if (x.diff(y) >= 0) {
            indexArr.push(i)
          } else {
            var d = this.$moment(arr[i].begin_time).format('x')
            this.addForm.cost_time_period_time[index].end_time = this.$moment(Number(d) - 1000)._d
            break
          }
        }
        for (var j = indexArr.length - 1; j >= 0; j--) {
          setTimeout(() => {
            this.addForm.cost_time_period_time.splice(indexArr[j], 1)
            this.addForm.cost_time_period_time[0].begin_time = begin_time
          }, 300)
        }
      }
    },
    // 结束时间发生改变时触发
    endTimeChange (index, end_time) {
      // console.log('--结束时间---index--', index);
      // console.log('--结束时间---end_time-', end_time);
      if (this.addForm.cost_time_period_time[index + 1]) {
        // 1、若下一个时段存在、则下一时段的开始时间为结束时间+1s
        var d = this.$moment(end_time).format('x')
        this.addForm.cost_time_period_time[index + 1].begin_time = this.$moment(Number(d) + 1000)._d
        // 2、遍历所有时间，若当前时段的结束时间 >= 之后时段的结束时间 则删除之后时段，否则结束循环，不做处理
        var arr = this.addForm.cost_time_period_time.concat()
        var indexArr = []
        var x = this.$moment(end_time)
        for (var i = (index + 1); i <= (arr.length - index); i++) {
          var y = this.$moment(arr[i].end_time)
          if (x.diff(y) >= 0) {
            indexArr.push(i)
          } else {
            break
          }
        }
        for (var j = indexArr.length - 1; j >= 0; j--) {
          this.delBillingTime(indexArr[j])
        }
      }
    },
    // 结束时间中的禁用数据
    addEndTime (index, item) {
      // 1、判断是否是第一个结束时段
      // 2、若是第一个结束时段，则可以选择所有时间 (传字符串)
      // 3、若不是第一个结束时段，则禁止选择 第一个时段的开始-当前时段的开始（传数组）
      if (index === 0) {
        return '00:00:00-23:59:59'
      } else {
        var begin_time = this.addForm.cost_time_period_time[0].begin_time
        var d = this.$moment(item.begin_time).format('x')
        var h = this.$moment(begin_time).format('x')
        var time = ['00:00:00-' + this.$moment(Number(h) + 1000).format('HH:mm:ss'), this.$moment(Number(d) + 1000).format('HH:mm:ss') + '-23:59:59']
        return time
      }
    },
    // 删除计费时段
    delBillingTime (index) {
      // 1、若删除的计费时段后面有计费时间，则将后面计费时段的开始时间改为 此计费时段的开始时间
      setTimeout(() => {
        if (this.addForm.cost_time_period_time[index + 1] && index !== (this.addForm.cost_time_period_time.length - 1)) {
          this.addForm.cost_time_period_time[index + 1].begin_time = this.addForm.cost_time_period_time[index].begin_time
        }
        this.addForm.cost_time_period_time.splice(index, 1)
      }, 300)
    },
    // 获取计费方案详情
    async fngetDetailsList (id) {
      const res = await getDetailsList({
        time_id: this.time_id
      })
      if (res && res.Code === 200) {
        var data = res.Data
        // console.log(res.Data);
        this.addForm._id = data._id
        this.addForm.scheme_name = data.scheme_name
        this.addForm.scheme_details = data.scheme_details
        this.addForm.free_time = data.time_period_details.free_time
        this.addForm.free_time_iscalculate = data.time_period_details.free_time_iscalculate
        this.addForm.billing_cycle = data.time_period_details.billing_cycle
        var arr = data.time_period_details.cost_time_period_time
        arr.forEach(item => {
          item.begin_time = new Date('2021-5-26 ' + item.begin_time)
          item.end_time = new Date('2021-5-26 ' + item.end_time)
        })
        this.addForm.cost_time_period_time = arr
        this.addForm.is_quota = data.time_period_details.is_quota
        this.addForm.quota_duration = data.time_period_details.is_quota ? data.time_period_details.quota_duration : ''
        this.addForm.quota_amount = data.time_period_details.is_quota ? data.time_period_details.quota_amount : ''
      }
    },
    // 新增/编辑
    submitAddForm () {
      // 对整个表单进行校验
      this.$refs.addFormRef.validate((valid) => {
        // 成功
        if (valid) {
          var arr = this.addForm.cost_time_period_time.concat()
          var start = this.$moment(arr[0].begin_time).format('x')
          var end = this.$moment(arr[arr.length - 1].end_time).format('x')
          // console.log('---start---', start);
          // console.log('---end---', start - end)
          if (start - end === 1000 || start - end === -86399000) {
            arr.forEach(item => {
              // console.log(item.begin_time);
              item.begin_time = this.$moment(new Date(item.begin_time)).format('HH:mm') + ':00'
              // console.log(item.end_time);
              item.end_time = this.$moment(new Date(item.end_time)).format('HH:mm') + ':59'
            })
            var obj = {}
            this.addForm.quota_amount = parseFloat(this.addForm.quota_amount).toFixed(2)
            obj = {
              scheme_name: this.addForm.scheme_name,
              scheme_details: this.addForm.scheme_details,
              time_period_details: {
                free_time: Number(this.addForm.free_time),
                free_time_iscalculate: this.addForm.free_time_iscalculate,
                billing_cycle: Number(this.addForm.billing_cycle),
                is_quota: this.addForm.is_quota,
                quota_duration: this.addForm.is_quota ? Number(this.addForm.quota_duration) : 0,
                quota_amount: this.addForm.is_quota ? parseFloat(this.addForm.quota_amount).toFixed(2) : 0,
                cost_time_period_time: arr
              }
            }
            if (this.title === '新增时段计费方案') {
              // console.log(obj);
              this.fnAddInterval(obj)
            } else {
              obj._id = this.addForm._id
              // console.log(obj);
              this.fnEditInterval(obj)
            }
          } else {
            this.$msg.warning('所有时段相加必须为24小时，请重新选择！')
          }
        } else {
          return false
        }
      })
    },
    // 新增时段计费方案
    async fnAddInterval (obj) {
      const res = await addInterval(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        // this.$router.go(-1)
        this.$emit('closeDialog', false)
      }
    },
    // 编辑时段计费方案接口
    async fnEditInterval (obj) {
      const res = await editInterval(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        // this.$router.go(-1)
        // this.goBack()
        this.$emit('closeDialog', false)
      }
    },
    defaultForm () {
      this.$emit('closeDialog', false)
    }
  }
}
</script>

<style scoped lang="less">
.demo-ruleForm {
  text-align: left;
  max-height: 650px;
  overflow-y: auto;

  .el-textarea,
  .el-select {
    width: 83%;
  }

  .el-input {
    width: 83%;
    margin-right: 10px;
  }

  .quota_box {
    display: flex;

    .selectInput {
      width: 30%;
    }
    .quota_box_input{
      width: 31.5%;
      .el-input{
        width: 90%;
      }
    }
  }

  .time-frame {
    .cost_time_period_time_input {
      width: 23%;

      .el-select {
        width: 100%;
      }

      .el-input {
        width: 100%;
      }
    }
    .iconButtom{
      width: 100px;
      display: flex;
      justify-content: start;
      align-items: center;
      .buttonCircle{
        margin-left:15px;padding:8px!important;
      }
      .buttonCircleDecline{
        padding:8px!important;
      }
    }
    // .el-form-item__content {
    //   margin-left: 120px;
    //   display: flex;
    //   align-items: center;

    //   .el-input {
    //     margin-right: 0;
    //   }

    //   .el-date-editor {
    //     width: 120px;
    //     text-align: center;
    //   }

    //   .el-select {
    //     width: 135px;
    //   }
    // }
  }
}

.labelWidth2 {
  min-width: 70px;
}

.box-card {
  position: relative;
  padding-bottom: 30px;
  margin: auto;

  .checkButton {
    position: absolute;
    right: 0px;
  }

  .checkButton2 {
    position: absolute;
    right: 100px;
  }
}
</style>
